import { useSelector } from 'react-redux'

import { State } from '../../reducers'
import ReloadBanner from './ReloadBanner'

const ReloadBannerProvider: React.FC = () => {
  const { isDashboard, isReloadRefreshButtonVisible } = useSelector((state: State) => state.loader)
  const { isMobile } = useSelector((state: State) => state)

  if (isReloadRefreshButtonVisible) {
    return <ReloadBanner isMobile={!!isMobile} modifier={isDashboard ? '' : 'top'} />
  } else {
    return null
  }
}

export default ReloadBannerProvider
