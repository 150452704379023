import { ReactComponent as WarningIconSvg } from 'app-images/icons/warning.svg'
import classNames from 'classnames'
import React, { FC } from 'react'

const ReloadBanner: FC<{ isMobile: boolean; modifier?: string }> = ({ isMobile, modifier }) => {
  const reloadTextPart = isMobile ? 'Reload' : 'Press F5'
  return (
    <div className={classNames('reload-banner', { 'reload-banner--mobile': isMobile }, modifier)}>
      <div className="reload-banner__icon">
        <WarningIconSvg />
      </div>
      <h4 className="reload-banner__title">
        Whoa, something happened! {reloadTextPart} or try again
      </h4>
      <button
        onClick={(): void => {
          window.location.reload()
        }}
        className={classNames(
          'button reload-banner__button',
          { 'button--lg': !isMobile },
          { 'button--md': isMobile }
        )}>
        Try again
      </button>
    </div>
  )
}

export default ReloadBanner
